import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Project from "./Project"

export default function Projects() {
  const { data } = useStaticQuery(graphql`
    {
      data: allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "project" } } }
        sort: { fields: frontmatter___weight }
      ) {
        edges {
          node {
            html
            id
            frontmatter {
              title
              gallery {
                id
                childImageSharp {
                  fixed(width: 1500) {
                    src
                  }
                }
              }
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 983, maxHeight: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  console.log("data:", data)
  return (
    <div className="c-projects">
      <div className="u-inner">
        <div className="o-grid o-grid--flush o-grid--spaced-large">
          {data.edges.map(({ node }) => (
            <Project key={node.id} node={node} />
          ))}
        </div>
      </div>
    </div>
  )
}
